import React, { useState, useRef, useEffect, useContext } from 'react';

import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import classNames from 'classnames';
import { motion, useMotionValue } from 'framer-motion';

import ButtonSecondary from '../ButtonSecondary';
import SlideShowNav from '../SlideShowNav/SlideShowNav';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { GradientContext, BackgroundContext } from '../../containers/BasePage';
import Link from 'next/link';
import { SwiperSlide } from 'swiper/react';
import dynamic from 'next/dynamic';

const SlideShow = dynamic(() => import('../SlideShow'), { ssr: false });

import s from './MediaBankModule.module.scss';

const MediaBankModule = ({
  titleTop,
  titleBottom,
  text,
  link,
  items = [],
  backgroundColor = '',
  anchorLink,
}) => {
  const [index, setIndex] = useState(0);
  const itemRefs = useRef([]);
  const gradient = useContext(GradientContext);
  const background = useContext(BackgroundContext);

  let bgColor = backgroundColor;
  if (background?.hasSetBg) {
    bgColor = background?.whiteBackground ? 'white' : 'black';
  }

  const [ref, isVisible] = useIntersectionObserver(0, undefined, true);

  const { t } = useTranslation();

  const rootClasses = classNames(s.Root, s[gradient], {
    [s.White]: bgColor !== 'black',
  });

  const titleClasses = classNames(s.TitleContainer, 'grid', {
    [s.Visible]: isVisible,
    [s.White]: bgColor !== 'black',
  });

  const textClasses = classNames(s.TextContainer, 'grid', {
    [s.Visible]: isVisible,
    [s.White]: bgColor !== 'black',
  });

  const buttonClasses = classNames(s.ButtonContainer, 'grid', {
    [s.Visible]: isVisible,
  });

  const itemClasses = classNames(s.Item, 'grid', {
    [s.Visible]: isVisible,
    [s.White]: bgColor !== 'black',
  });

  const linkClasses = classNames(s.Link, 'grid', {
    [s.White]: bgColor !== 'black',
  });

  return (
    <section id={anchorLink} className={rootClasses} ref={ref}>
      <h2 className={s.Title}>
        <span className={titleClasses}>
          <span className={s.TitleTop}>{titleTop}</span>
          <span className={s.TitleBottom}>{titleBottom}</span>
        </span>
      </h2>
      <div className={`${textClasses} grid`}>
        <p className={s.Text}>{text}</p>
      </div>
      <div className={buttonClasses}>
        {!!link && (
          <ButtonSecondary
            asLink
            href={link}
            icon={
              bgColor !== 'black'
                ? 'icon-external-black'
                : 'icon-external-white'
            }
            borderColor={bgColor !== 'black' ? 'black' : 'white'}
            openInNewTab
            modifiers={['MediaBank']}>
            <div className={s.ButtonText}>{t('MediaBank.visit')}</div>
          </ButtonSecondary>
        )}
      </div>
      <SlideShow
        breakpoints={{
          // when window width is >= 320px
          100: {
            slidesPerView: 'auto',
            spaceBetween: 8,
          },
        }}
        onSlideChange={(e) => {
          setIndex(e.activeIndex);
        }}>
        {items.length > 0 &&
          items.map((item, i) => {
            return (
              <SwiperSlide
                className={`${itemClasses} ${i === 0 && s.FirstItem} ${
                  i === items.length - 1 && s.LastItem
                }`}
                key={i}
                data-index={i}
                ref={(el) => (itemRefs.current[i] = el)}>
                <div className={s.ImageContainer}>
                  <Image
                    className={s.Image}
                    src={item?.image?.url}
                    alt={item?.image?.alt}
                    title={item?.image?.title}
                    fill
                    draggable={false}
                  />
                </div>
                {!!item?.link && (
                  <Link href={item?.link} legacyBehavior>
                    <a target="_blank" className={linkClasses}>
                      <Image
                        width={15}
                        height={15}
                        src={
                          bgColor !== 'black'
                            ? '/img/icon-external-black.svg'
                            : '/img/icon-external-white.svg'
                        }
                        alt=""
                        className={s.External}
                      />
                      <span className={s.LinkText}>{t('MediaBank.view')}</span>
                    </a>
                  </Link>
                )}
              </SwiperSlide>
            );
          })}
        <SlideShowNav
          indicators={items}
          isVisible={isVisible}
          indicatorIndex={index}
          setIndicatorIndex={setIndex}
          color={bgColor}
        />
      </SlideShow>
    </section>
  );
};

MediaBankModule.propTypes = {};

MediaBankModule.defaultProps = {};

export default MediaBankModule;
